body{
	padding-top: 74px;
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
	font-weight: 400;
	color: #101517;
}

h1, h2, h3, h4, h5, h6{
	font-family: 'Lora', sans-serif;
	font-weight: 400;
}

::selection {
	background: $brand-primary;
	color: #fff;
	@include opacity(1);
}
::-moz-selection {
	background: $brand-primary;
	color: #fff;
	@include opacity(1);
}

textarea{
	resize: none;
}

.form-control{
	@include border-top-radius(0);
	@include border-bottom-radius(0);
}

.btn{
	padding: 15px 25px;
	font-size: 0.875em;
	font-weight: 400;
	text-transform: uppercase;
	@include border-top-radius(0);
	@include border-bottom-radius(0);

	&.btn-primary{
		border: $brand-primary;

		&.border{
			background: none;
			border: $brand-primary 2px solid;
			color: $brand-primary;
		}
	}
	&.btn-white{
		&.border{
			background: none;
			border: #fff 2px solid;
			color: #fff;
		}
	}
	&.btn-black{
		&.border{
			background: none;
			border: #101517 2px solid;
			color: #101517;
		}
	}
}

@media (min-width: $screen-sm-min) {
	body{
		padding-top: 93px;
	}
}

@media (min-width: $screen-md-min) {
}

@media (min-width: $screen-lg-min) {
	.btn{
		&.border{
			position: relative;
			z-index: 1;
			-webkit-transition: color 0.3s;
			-moz-transition: color 0.3s;
			transition: color 0.3s;

			&:after {
				content: '';
				position: absolute;
				z-index: -1;
				width: 0%;
				height: 100%;
				top: 0;
				left: 0;
				-webkit-transition: width 0.3s;
				-moz-transition: width 0.3s;
				transition: width 0.3s;
			}
			&:hover:after{
				width: 100%;
			}
			&.white{
				&:hover{
					color: $brand-primary;
				}
				&:after{
					background: #fff;
				}
			}
			&.btn-primary{
				&:after{
					background: $brand-primary;
				}
				&:hover, &:focus{
					background: none;
				}
				&:hover{
					color: #fff;
				}
			}
			&.btn-default{
				&:after{
					background: #ADADAD;
				}
				&:hover, &:focus{
					background: none;
				}
				&:hover{
					color: #fff;
				}
			}
			&.btn-white{
				&:after{
					background: #fff;
				}
				&:hover, &:focus{
					background: none;
				}
				&:hover{
					color: $brand-primary;
				}
			}
			&.btn-black{
				&:after{
					background: #101517;
				}
				&:hover, &:focus{
					background: none;
				}
				&:hover{
					color: #fff;
				}
			}
		}
	}
}

@media (min-width: 1500px) {
	.container{
		width: 1370px;
	}
}

@media only screen and (min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2/1),
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min-device-pixel-ratio: 2) {
}
