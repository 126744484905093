.header{
	.navbar-default{
		margin: 0;
		background: $brand-secondary;
		border: none;
		@include border-top-radius(0);
		@include border-bottom-radius(0);

		transition: top 0.2s ease-in-out;
		@include border-top-radius(0);
		@include border-bottom-radius(0);

		&:before{
			content: '';
			position: absolute;
			top: 0; right: 0;
			display: block;
			width: 52px; height: 100%;
			background: $brand-primary;
		}
		&.fixed{
			position: fixed;
			top: 0; bottom: auto;

			-webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.25);
			box-shadow: 0 0 5px 0 rgba(0,0,0,0.25);
		}
		&.nav-up{
			top: -75px;

			-webkit-box-shadow: none;
			box-shadow: none;
		}
		&.nav-down{
			top: 0;
		}
		> .container{
			.navbar-header{
				position: relative;
				height: 74px;
				text-align: center;

				.navbar-toggle{
					position: absolute;
					top: 50%; right: 0;
					padding: 0;
					margin: 0;
					border: none;
					@include border-top-radius(0%);
					@include border-bottom-radius(0%);
					-ms-transform: translate(0, -50%);
					-webkit-transform: translate(0, -50%);
					transform: translate(0, -50%);
					box-shadow: none;

					.icon-bar{
						background: #fff;
					}
					&:hover, &:focus{
						background: none;

						.icon-bar{
							background: #fff;
						}
					}
				}
				.navbar-brand{
					display: inline-block;
					height: auto;
					padding: 15px 15px 15px 0;

					.img-responsive{
						display: inline-block;
						width: 70px;
					}
				}
			}
			.navbar-collapse{
				position: relative;
				z-index: 10;
				padding: 0 15px;
				margin: 0 -15px;
				background: $brand-primary;
				border: none;

				.navbar-nav{
					&.primary-navbar{
						> li{
							> a{
								font-family: 'Lora', sans-serif;
								font-size: 0.875em;
								font-weight: 400;
								text-transform: uppercase;
								color: #fff;

								&:hover, &:focus{
									text-decoration: none;
								}
							}
							&.active, &.open{
								> a{
									background: none;
									color: #2B4757;
								}
							}
							> ul{
								> li{
									> a{
										font-family: 'Lora', sans-serif;
										font-size: 0.875em;
										font-weight: 400;
										color: #fff;

										&:hover, &:focus{
											background: none;
											text-decoration: none;
										}
									}
									&.active, &.open{
										> a{
											background: none;
											color: #2B4757;
										}
									}
								}
							}
						}
					}
					&.social-navbar{
						> li{
							display: inline-block;

							> a{
								padding: 10px 10px;
								color: #fff;
								
								&:hover, &:focus{
									background: none;
									text-decoration: none;
								}
							}
						}
					}
				}
			}
		}
	}
	.fixed-contacts{
		position: absolute;
		top: 160px; right: 15px;
		z-index: 1000;

		> div{
			position: relative;
			width: 51px; height: 51px;
			margin-bottom: 15px;

			&:before{
				content: '';
				position: absolute;
				top: 0; left: 0;
				z-index: 10;
				display: block;
				width: 100%; height: 100%;
				margin: -2px 0 0 -2px;
				background: transparent center center no-repeat;
			}
			> a{
				position: absolute;
				top: 6px; left: 6px;
				z-index: 20;
				display: block;
				width: 36px; height: 36px;
				overflow: hidden;
				text-indent: -999px;
				border: #fff 2px solid;
				@include rotate(45deg);

				&:hover, &:focus{
					text-decoration: none;
				}
			}
			&.email{
				&:before{
					background-image: url('../images/icon-mail.png');
					background-size: 19px 14px;
				}
			}
			&.telephone{
				&:before{
					background-image: url('../images/icon-phone.png');
					background-size: 23px 23px;
				}
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	.header{
		.navbar-default{
			&.nav-up{
				top: -93px;
			}
			&:before{
				top: 0; right: auto; left: 50%;
				width: 50%;
				margin-left: 375px;
			}
			> .container{
				position: relative;
				z-index: 10;

				.navbar-header{
					position: static;
					height: 93px;

					.navbar-brand{
						padding: 15px;
						margin-left: 0;
						border-left: #CAD1D5 1px solid;

						.img-responsive{
							display: inline-block;
							width: 100px;
						}
					}
				}
				.navbar-collapse{
					position: static;
					margin: 0;
					background: none;

					.navbar-nav{
						&.primary-navbar{
							position: absolute;
							top: 0; left: 146px;

							> li{
								border-left: #CAD1D5 1px solid;

								> a{
									padding: 37px 12px 36px 12px;
									font-size: 0.750em;
									color: #2B4757;

									&:hover{
										color: #CAD1D5;
									}
									> span{
										position: relative;

										.caret{
											position: absolute;
											bottom: -10px; left: 50%;
											color: #94A3AB;
											-ms-transform: translate(-50%, 0);
											-webkit-transform: translate(-50%, 0);
											transform: translate(-50%, 0);
										}
									}
								}
								&.active, &.open{
									> a{
										color: $brand-primary;
									}
								}
								> ul{
									> li{
										> a{
											font-size: 0.750em;

											&:hover{
												color: #CAD1D5;
											}
										}
										&.active, &.open{
											> a{
												color: #2B4757;
											}
										}
									}
								}
								.dropdown-menu{
									left: 50%;
									width: 200px;
									max-width: inherit;
									padding-top: 10px;
									padding-bottom: 10px;
									margin-left: -100px;
									background: $brand-primary;
									border: none;
									@include border-top-radius(0);
									@include border-bottom-radius(0);
									box-shadow: none;

									&:before{
										content: '';
										display: block;
										position: absolute;
										top: -7px; left: 50%;
										width: 20px; height: 20px;
										margin-left: -10px;
										background: $brand-primary;
										border-bottom: none;
										@include rotate(45deg);
									}
								}
							}
						}
						&.social-navbar{
							position: absolute;
							top: 0; right: 0;
							padding: 26px 0 27px 9px;
							background: $brand-primary;
							
							> li{
								display: inline-block;

								> a{
									padding: 10px 10px;
									color: #fff;
									
									&:hover{
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: $screen-md-min) {
	.header{
		.navbar-default{
			&:before{
				margin-left: 485px;
			}
			> .container{
				.navbar-header{
					.navbar-brand{
						padding-left: 75px;
						padding-right: 75px;
					}
				}
				.navbar-collapse{
					.navbar-nav{
						&.primary-navbar{
							left: 266px;

							> li{
								> a{
									padding-left: 20px;
									padding-right: 19px;
									font-size: 0.750em;
								}
								> ul{
									> li{
										> a{
											font-size: 0.750em;
										}
									}
								}
							}
						}
						&.social-navbar{
							padding-left: 50px;
						}
					}
				}
			}
		}
	}
}

@media (min-width: $screen-lg-min) {
	.header{
		.navbar-default{
			&:before{
				margin-left: 585px;
			}
			> .container{
				.navbar-collapse{
					.navbar-nav{
						&.primary-navbar{
							> li{
								> a{
									padding-left: 35px;
									padding-right: 35px;
									font-size: 0.875em;
								}
								> ul{
									> li{
										> a{
											font-size: 0.875em;
										}
									}
								}
							}
						}
						&.social-navbar{
							padding-right: 30px;
						}
					}
				}
			}
		}
	}
}

@media (min-width: 1500px) {
	.header{
		.navbar-default{
			&:before{
				margin-left: 685px;
			}
			> .container{
				.navbar-collapse{
					.navbar-nav{
						&.primary-navbar{
							> li{
								> a{
									padding-left: 60px;
									padding-right: 60px;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media only screen and (min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2/1),
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min-device-pixel-ratio: 2) {
	.header{
		.fixed-contacts{
			> div{
				&.email{
					&:before{
						background-image: url('../images/icon-mail@2x.png');
					}
				}
				&.telephone{
					&:before{
						background-image: url('../images/icon-phone@2x.png');
					}
				}
			}
		}
	}
}
