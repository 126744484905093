.footer{
	padding-bottom: 30px;
	margin-top: 140px;
	background: $brand-secondary;

	.footer-map{
		width: 100%; height: 200px;
		margin-top: -100px;
		margin-bottom: 30px;
		background: $brand-secondary;

		> div{
			width: 100%; height: 100%;

			> div{
				width: 100%; height: 100%;
			}
			.marker{
				display: none;
			}
		}
	}

	.brand-contacts{
		margin-bottom: 20px;

		.title{
			display: block;
			margin: 0 0 5px 0;
			font-family: 'Lora', sans-serif;
			font-size: 1.250em;
			font-weight: 400;
			text-transform: uppercase;
			color: $brand-primary;
		}
		p{
			font-size: 0.813em;
			line-height: 2;
		}
		a{
			color: #101517;
		}
	}

	.brand-legal{
		ul{
			padding: 0;
			margin: 0;
			list-style: none;

			li{
				float: left;
				margin: 0 20px 10px 0;
				font-size: 0.750em;
				font-weight: 300;

				a{
					color: #101517;
				}
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	.footer{
		.brand-legal{
			ul{
				li{
					&.credits{
						float: right;
					}
				}
			}
		}
	}
}