.home-slider{
	position: relative;
	padding-bottom: 40px;
	margin-bottom: 40px;
	overflow: hidden;
	background: #F3F3F3;

	&:before{
		content: '';
		position: absolute;
		top: 0; right: 0;
		display: block;
		width: 52px; height: 100%;
		background: $brand-primary;
	}
	> .container{
		position: relative;
		z-index: 20;
	}
	.slick{
		margin: 0;

		.slider-item{
			background: #F3F3F3;
			outline: none;

			> div{
				position: relative;
				height: 530px;
				background: transparent center center no-repeat;
				background-size: cover;

				.caption{
					position: absolute;
					bottom: 0; right: 0;
					z-index: 20;
					width: 100%;
					background: rgba(#000, 0.6);
					color: #fff;

					> .row{
						> div{
							> div{
								padding: 30px 30px 45px 30px;

								.title{
									display: block;
									margin: 0;
									font-family: 'Lora', sans-serif;
									font-size: 2.500em;
									font-weight: 400;
								}
								.btn{
									margin-top: 20px;
								}
							}
						}
					}
				}
			}
		}
		.slick-dots{
			position: absolute;
			bottom: -13px; left: 50%;
			height: 20px;
			padding: 0;
			margin: 0;
			list-style: none;
			-ms-transform: translate(-50%, 0);
			-webkit-transform: translate(-50%, 0);
			transform: translate(-50%, 0);

			li{
				position: relative;
				display: inline-block;
				width: 20px; height: 20px;
				margin: 0 10px;

				button{
					position: absolute;
					top: 0; left: 0;
					display: block;
					width: 16px; height: 16px;
					padding: 0;
					margin: 0;
					overflow: hidden;
					text-indent: -999px;
					background: #CAD1D5;
					border: #fff 2px solid;
					@include rotate(-45deg);
					outline: none;
				}
				&.slick-active{
					button{
						background: $brand-primary;
					}
				}
			}
		}
	}
}

.home-cats{
	margin-bottom: -30px;

	.item{
		margin-bottom: 30px;

		> a{
			display: block;
			border: #CAD1D5 1px solid;

			&:hover, &:focus{
				text-decoration: none;
			}
			&:hover{

			}
			.image{
				height: 180px;
				background: transparent center center no-repeat;
				background-size: cover;
			}
			.row{
				margin-left: 0;
				margin-right: 0;

				> div{
					&.caption{
						
						> div{
							padding: 20px 5px 0 5px;
						}
						.title{
							display: block;
							margin: 0 0 10px 0;
							font-family: 'Lora', sans-serif;
							font-size: 1.500em;
							font-weight: 400;
							color: $brand-primary;
						}
						p{
							margin-bottom: 20px;
							font-size: 0.813em;
							letter-spacing: 0.03em;
							color: #2B4757;
						}
					}
					&.button{
						text-align: right;
						background: $brand-secondary;

						> div{
							padding: 20px 5px;
						}
						.btn{
							padding: 10px 15px;
							font-size: 0.750em;
							border-width: 1px;
						}
					}
				}
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	.home-slider{
		&:before{
			right: auto; left: 50%;
			width: 50%;
			margin-left: 164px;
		}
	}
	.home-cats{
		.item{
			> a{
				position: relative;

				.row{
					padding-bottom: 79px;

					.button{
						position: absolute;
						bottom: 0; left: 0;
						width: 100%;
					}
				}
			}
		}
	}
}

@media (min-width: $screen-md-min) {
	.home-slider{
		&:before{
			margin-left: 223px;
		}
		.slick{
			.slider-item{
				> div{
					&:before{
						content: '';
						position: absolute;
						top: 0; right: 0;
						display: block;
						width: 50%; height: 100%;
						background: rgba(#000, 0.6);
					}
					.caption{
						background: none;

						> .row{
							> div{
								> div{
									padding-bottom: 100px;
									
									.title{
										font-size: 4.375em;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: $screen-lg-min) {
	.home-slider{
		&:before{
			margin-left: 293px;
		}
	}
	.btn{
		&.border{
			position: relative;
			z-index: 1;
			-webkit-transition: color 0.3s;
			-moz-transition: color 0.3s;
			transition: color 0.3s;

			&:after {
				content: '';
				position: absolute;
				z-index: -1;
				width: 0%;
				height: 100%;
				top: 0;
				left: 0;
				-webkit-transition: width 0.3s;
				-moz-transition: width 0.3s;
				transition: width 0.3s;
			}
			&:hover:after{
				width: 100%;
			}
			&.white{
				&:hover{
					color: $brand-primary;
				}
				&:after{
					background: #fff;
				}
			}
			&.btn-primary{
				&:after{
					background: $brand-primary;
				}
				&:hover, &:focus{
					background: none;
				}
				&:hover{
					color: #fff;
				}
			}
			&.btn-default{
				&:after{
					background: #ADADAD;
				}
				&:hover, &:focus{
					background: none;
				}
				&:hover{
					color: #fff;
				}
			}
			&.btn-white{
				&:after{
					background: #fff;
				}
				&:hover, &:focus{
					background: none;
				}
				&:hover{
					color: $brand-primary;
				}
			}
			&.btn-black{
				&:after{
					background: #101517;
				}
				&:hover, &:focus{
					background: none;
				}
				&:hover{
					color: #fff;
				}
			}
		}
	}

	.home-cats{
		.item{
			> a{
				position: relative;
				overflow: hidden;

				.row{
					position: relative;
					padding-bottom: 1px;

					> div{
						&.caption{
							position: static;

							> div{
								padding-bottom: 20px;
							}
							&:after{
								content: '';
								position: absolute;
								top: 0; right: 0;
								display: block;
								width: 41.6666666667%; height: 100%;
								background: $brand-secondary;

								transition: background 0.3s ease-in-out;
							}
						}
						&.button{
							position: absolute;
							bottom: 0; left: auto; right: 0;
							width: auto;
							background: none;

							.btn{
								transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out;

								&:after{
									background: none;
								}
							}
						}
					}
				}
				&:hover{
					.row{
						> div{
							&.caption{
								&:after{
									background: $brand-primary;
								}
							}
							&.button{
								.btn{
									border-color: #fff;
									color: #fff;

									&:hover{
										color: #fff;

										&:after{
											background: none;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (min-width: 1500px) {
	.container{
		width: 1370px;
	}

	.home-slider{
		&:before{
			margin-left: 393px;
		}
	}

	.home-cats{
		.item{
			> a{
				.image{
					height: 210px;
				}
			}
		}
	}
}
